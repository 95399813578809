import { Injectable } from '@angular/core';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GeminiService {
  private apiKey = environment.geminiKey;

  constructor() {}

  async generateText(prompt: string, chatHistory?: any) {
    //console.log('Generating text with prompt:', prompt);
    //console.log('Chat history:', chatHistory);
    const genAI = new GoogleGenerativeAI(this.apiKey);
    const model = genAI.getGenerativeModel({
      model: 'gemini-1.5-flash-8b',
      systemInstruction:
        'Your name is JIM(Joint Information Manager), you are an AI assistant Fleet manager for Downtime Terminator, powered by Revit Auto',
      generationConfig: {
        responseMimeType: 'text/plain',
      },
    }); // Choose the desired Gemini model
    if (chatHistory) {
      const result = await model.generateContent({
        contents: chatHistory,
      });
      // //console.log(
      //   'Total tokens:',
      //   result.response.usageMetadata?.totalTokenCount
      // );
      return result.response.text();
    } else {
      const result = await model.generateContent(prompt);
      // //console.log(
      //   'Total tokens:',
      //   result.response.usageMetadata?.totalTokenCount
      // );
      return result.response.text();
    }
  }
}
