import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Inject,
  NgZone,
  ViewChild,
} from '@angular/core';
// Common Module
import { CommonModule } from '@angular/common';
// Material
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
// TextField AutoResize
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
// Others
import { eRFbWhOps } from '../../enums/efb-where-op';
import { eRTable } from '../../enums/er-tables';
import { TextToHtmlPipe } from '../../pipes/texttohtml.pipe';
import { AuthService } from '../../services/auth.service';
import { FirebaseService } from '../../services/firebase.service';
import { GeminiService } from '../../services/gemini.service';
import { DeleteConversationComponent } from './delete-conversation/delete-conversation.component';
import { RenameConversationComponent } from './rename-conversation/rename-conversation.component';

// Conversations Interface
export interface Conversation {
  messages: Messages[];
  title: string;
  userId: string;
}

// Messages Interface
export interface Messages {
  message: string;
  sender: string;
}

// AI Assistant icon svg
const AI_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M323-160q-11 0-20.5-5.5T288-181l-78-139h58l40 80h92v-40h-68l-40-80H188l-57-100q-2-5-3.5-10t-1.5-10q0-4 5-20l57-100h104l40-80h68v-40h-92l-40 80h-58l78-139q5-10 14.5-15.5T323-800h97q17 0 28.5 11.5T460-760v160h-60l-40 40h100v120h-88l-40-80h-92l-40 40h108l40 80h112v200q0 17-11.5 28.5T420-160h-97Zm217 0q-17 0-28.5-11.5T500-200v-200h112l40-80h108l-40-40h-92l-40 80h-88v-120h100l-40-40h-60v-160q0-17 11.5-28.5T540-800h97q11 0 20.5 5.5T672-779l78 139h-58l-40-80h-92v40h68l40 80h104l57 100q2 5 3.5 10t1.5 10q0 4-5 20l-57 100H668l-40 80h-68v40h92l40-80h58l-78 139q-5 10-14.5 15.5T637-160h-97Z"/></svg>`;
const AI_ICON_VIOLET = `
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#8659f1"><path d="M323-160q-11 0-20.5-5.5T288-181l-78-139h58l40 80h92v-40h-68l-40-80H188l-57-100q-2-5-3.5-10t-1.5-10q0-4 5-20l57-100h104l40-80h68v-40h-92l-40 80h-58l78-139q5-10 14.5-15.5T323-800h97q17 0 28.5 11.5T460-760v160h-60l-40 40h100v120h-88l-40-80h-92l-40 40h108l40 80h112v200q0 17-11.5 28.5T420-160h-97Zm217 0q-17 0-28.5-11.5T500-200v-200h112l40-80h108l-40-40h-92l-40 80h-88v-120h100l-40-40h-60v-160q0-17 11.5-28.5T540-800h97q11 0 20.5 5.5T672-779l78 139h-58l-40-80h-92v40h68l40 80h104l57 100q2 5 3.5 10t1.5 10q0 4-5 20l-57 100H668l-40 80h-68v40h92l40-80h58l-78 139q-5 10-14.5 15.5T637-160h-97Z"/></svg>`;

@Component({
  selector: 'ai-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    TextToHtmlPipe,
    MatProgressSpinnerModule,
    MatMenuModule,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './ai-dialog.component.html',
  styleUrl: './ai-dialog.component.sass',
})
export class AiDialogComponent {
  // html references
  @ViewChild('messageContainer') messageContainer: ElementRef | undefined;
  // Screen width reader
  screenWidth: number = window.innerWidth;
  screenHeight: number = window.innerHeight;
  isMobile = this.screenWidth < 768;
  isTablet = this.screenWidth < 1024 && this.screenWidth >= 768;
  @HostListener('window:resize', ['$event']) onWindowResize(event: any) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isMobile = this.screenWidth < 768;
    this.isTablet = this.screenWidth < 1024 && this.screenWidth >= 768;
  }

  // Injections
  auths = inject(AuthService);
  fbs = inject(FirebaseService);
  gemini = inject(GeminiService);

  // Variables
  fullscreen = false;
  message: string = '';
  messages: Messages[] = [];
  showMore: boolean = false;
  username: string = '';
  isGenerating = false;
  conversations: { id: string; data: Conversation }[] = [];
  selectedConversation: { id: string; data: Conversation } | undefined;

  // Textarea auto resize
  @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;

  // @HostListener('window:resize', ['$event'])
  // // Get the device screen width on resize
  // onResize(event: any) {
  //   //console.log('Width:', event.target.innerWidth);
  // }

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<AiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _ngZone: NgZone,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    iconRegistry.addSvgIconLiteral(
      'ai-icon',
      sanitizer.bypassSecurityTrustHtml(AI_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'ai-icon-violet',
      sanitizer.bypassSecurityTrustHtml(AI_ICON_VIOLET)
    );
  }

  // On Init
  async ngOnInit() {
    this.username = this.auths?.userDetails?.fullname || '';
    // Check if the dialog is called with a prompt on the data
    if (this.data?.prompt) {
      this.message = this.data.prompt;
    }
    // Get User's Chats/Conversations
    await this.getConversations();
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close();
  }

  // Toggle fullscreen
  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    let width = '50vh';
    let height = '70vh';
    if (this.fullscreen) {
      if (this.isMobile) {
        width = '90vw';
        height = '70vh';
      } else if (this.isTablet) {
        width = '70vw';
        height = '70vh';
      } else {
        width = '80vw';
        height = '70vh';
      }
    } else {
      if (this.isMobile) {
        width = '90vw';
        height = '50vh';
      } else if (this.isTablet) {
        width = '55vw';
        height = '50vh';
      } else {
        width = '40vw';
        height = '60vh';
      }
    }
    this.dialogRef.updateSize(width, height);
  }

  // Send message
  async sendMessage() {
    const message = this.message.trim();
    if (this.message) {
      // Create a new conversation if it's the first message
      if (this.messages.length === 0) {
        // Get the title for the conversation
        const title = await this.autoGenerateTitle(message);
        const request = await this.fbs.addDoc(eRTable.jimChats, {
          title: title,
          userId: this.auths.userId || '',
          messages: [{ message, sender: 'user' }],
        });
        if (request) {
          this.selectedConversation = {
            id: request.id,
            data: {
              title: title,
              userId: this.auths.userId || '',
              messages: [{ message, sender: 'user' }],
            },
          };
          // Get User's Chats/Conversations
          await this.getConversations();
        }
      }
      // Set The Generating flag
      this.isGenerating = true;
      this.messages.push({ message, sender: 'user' });
      const scrollHeight = this.messageContainer?.nativeElement.scrollHeight;
      // Scroll to bottom
      setTimeout(() => {
        this.messageContainer?.nativeElement.scrollTo({
          top: scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
      this.message = '';
      // Set The Generating flag
      this.isGenerating = true;
      const scrollHeightResponse =
        this.messageContainer?.nativeElement.scrollHeight;
      // Generate the chat history for the new message
      const chatHistory = await this.generateChatHistory(
        this.messages,
        message
      );
      // //console.log('Chat History:', chatHistory);
      this.gemini
        .generateText(message, chatHistory)
        .then(async (response) => {
          this.messages.push({ message: response, sender: 'ai' });
          // Add the message to the conversation in the database
          if (this.selectedConversation) {
            await this.fbs.setDocById(
              eRTable.jimChats,
              this.selectedConversation.id,
              { messages: this.messages }
            );
          }
          setTimeout(() => {
            this.messageContainer?.nativeElement.scrollTo({
              top: scrollHeightResponse + 50,
              behavior: 'smooth',
            });
          }, 100);
          this.isGenerating = false;
        })
        .catch((error) => {
          console.error('Error:', error);
          this.messages.push({
            message:
              'Sorry, I am not able to process your request at the moment.',
            sender: 'ai',
          });
          // Add the message to the conversation in the database
          if (this.selectedConversation) {
            this.fbs.setDocById(
              eRTable.jimChats,
              this.selectedConversation.id,
              { messages: this.messages }
            );
          }
          setTimeout(() => {
            this.messageContainer?.nativeElement.scrollTo({
              top: scrollHeightResponse + 50,
              behavior: 'smooth',
            });
          }, 100);
          this.isGenerating = false;
        });
    }
  }

  // Resize textarea
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autosize) {
        this.autosize.resizeToFitContent(true);
      }
    });
  }

  // New Conversation
  newConversation() {
    this.messages = [];
    this.message = '';
    this.selectedConversation = undefined;
  }

  // get User's Chats/Conversations
  async getConversations() {
    this.auths.showOverlay();
    this.conversations = [];
    const userId = this.auths.userId || '';
    try {
      const ob = this.fbs.fbOrder('createdAt', 'desc');
      let w = [];
      w.push(this.fbs.fbWhere('userId', userId, eRFbWhOps.equal));
      const where = this.fbs.fbWhereN(w);
      const query = this.fbs.fbQueryN(eRTable.jimChats, where, ob);
      const request = await this.fbs.getDocsFromQuery(query);
      if (request) {
        this.conversations = request;
      }
      this.auths.hideOverlay();
    } catch (error) {
      console.error('Error:', error);
      this.auths.hideOverlay();
    }
  }

  // Select Conversation
  async selectConversation(conversation: { id: string; data: Conversation }) {
    // Fetch the conversation messages
    const request = await this.fbs.getDocById(
      eRTable.jimChats,
      conversation.id
    );
    const response = request.data();
    this.messages = response?.['messages'] || [];
    this.message = '';
    this.selectedConversation = conversation;
  }

  async renameConversation(conversation: { id: string; data: Conversation }) {
    const dialogRef = this.dialog.open(RenameConversationComponent, {
      data: conversation.data.title,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.fbs.setDocById(eRTable.jimChats, conversation.id, {
          title: result,
        });
        this.snackBar.open('Conversation successfully renamed', 'OK', {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
        });
        // Get User's Chats/Conversations
        await this.getConversations();
      }
    });
  }

  async deleteConversation(conversation: { id: string; data: Conversation }) {
    const dialogRef = this.dialog.open(DeleteConversationComponent);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.fbs.fbDelete(eRTable.jimChats, conversation.id);
        this.snackBar.open('Conversation successfully deleted', 'OK', {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
        });
        // If the conversation is the selected conversation, create a new conversation
        if (this.selectedConversation?.id === conversation.id) {
          this.newConversation();
        }
        // Get User's Chats/Conversations
        await this.getConversations();
      }
    });
  }

  // Auto Generate the conversation title
  async autoGenerateTitle(message: string) {
    if (message) {
      const prompt = `Generate a 2 or 3 word title for this chat, based on this message: ${message}`;
      const title = await this.gemini.generateText(prompt);
      return title;
    }
    return 'New Conversation';
  }

  // Generate chat history
  async generateChatHistory(messages: Messages[], prompt: string) {
    // Return the chat history in the following format: [ { role: "user" | "model", parts: [ { text: "message" } ] } ]
    const chatHistory = [];
    for (const message of messages) {
      const role = message.sender === 'user' ? 'user' : 'model';
      chatHistory.push({ role: role, parts: [{ text: message.message }] });
    }
    // Push the prompt message
    chatHistory.push({ role: 'user', parts: [{ text: prompt }] });
    //console.log('Chat History:', chatHistory);
    return chatHistory;
  }
}
