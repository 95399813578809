import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'textToHtml',
  standalone: true,
})
export class TextToHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const lines = value.split('\n');
    let output = '';

    lines.forEach((line) => {
      if (line.startsWith('**') && line.endsWith(':**')) {
        output += `<strong>${line.substring(2, line.length - 2)}</strong>`;
      } else {
        // It menans there is a bullet point on the line
        if (line.startsWith('*')) {
          // Get the phrases that start with '**' and end with ':**' inside a line
          const phrases = line.split('**');
          for (let i = 0; i < phrases.length; i++) {
            if (phrases[i].endsWith(':')) {
              output += `<strong>${phrases[i]}</strong>`;
            } else if (phrases[i].startsWith('*')) {
              output += '&bull; ';
            } else {
              output += phrases[i];
            }
          }
        } else {
          // Get the phrases that start with '**' and end with ':**' inside a line
          const phrases = line.split('**');
          for (let i = 0; i < phrases.length; i++) {
            if (phrases[i].endsWith(':')) {
              output += `<strong>${phrases[i]}</strong>`;
            } else {
              // Add strong to the odd phrases
              if (i % 2 !== 0) {
                output += `<strong>${phrases[i]}</strong>`;
              } else {
                output += phrases[i];
              }
            }
          }
        }
        // Add a line break between paragraphs except the last one
        if (lines.indexOf(line) !== lines.length - 1) {
          output += '<br>';
        }
      }
    });

    return output;
  }

  // Get all the words
  // const words = line.split(' ');
  // //console.log('Words:', words);
  // // If '*' return bull
  // let lineP = '';
  // for (let i = 0; i < words.length; i++) {
  //   if (words[i] === '*') {
  //     output += '&bull; '
  //   } else {
  //     if (words[i].startsWith('**') && words[i].endsWith(':**')) {
  //       output += `<strong>${words[i].substring(2, words[i].length - 2)}</strong>`;
  //     } else {
  //       lineP += words[i] + ' ';
  //     }
  //   }
  // }
}
